import { React, useState } from "react";
import "./nav.css";
import { AiFillHome } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { RiBookFill } from "react-icons/ri";
import { RiServiceFill } from "react-icons/ri";
import { MdMessage } from "react-icons/md";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  // Change the navigation icons and location.
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === "#" ? "active" : ""}>
        <AiFillHome />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <FaUser />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <RiBookFill />
      </a>
      <a 
        href="#contact" 
        onClick={() => setActiveNav('#contact')}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <MdMessage />
      </a>
    </nav>
  );
};

export default Nav;
