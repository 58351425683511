import React from 'react';
import './footer.css';
import {FaFacebookF} from 'react-icons/fa';
import {BsInstagram} from 'react-icons/bs';
import {BsTwitter} from 'react-icons/bs';

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Created By Elijah Camara</a>

      <p className='footer__description'>
        This is a react web application that I built from scratch. It was styled using origin CSS styling.
        The background was created with TSParticles.
      </p>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/elijah.camara.90/" target='_blank' rel="noreferrer"><FaFacebookF /></a>
        <a href="https://www.instagram.com/elijahcamara/" target='_blank' rel="noreferrer"><BsInstagram /></a>
        <a href="https://twitter.com/CamaraElij92082/" target='_blank' rel="noreferrer"><BsTwitter /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Elijah Camara. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer