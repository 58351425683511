import React, { useRef } from 'react';
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';
import emailjs from 'emailjs-com';


const Contacts = () => {
  const phone_num = '+1(317)466-0608'

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9913vkf', 'template_s71od4y', form.current, 'GlErRo0PfTnqR9nXt')

    e.target.reset()
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <a href="mailto:lijecam@gmail.com" target='_blank' className='outside__link'>
            <article className='contact__option'>
              <MdOutlineEmail className='contact__option-icon'/>
              <h4>Email</h4>
              <h5>lijecam@gmail.com</h5>
              <a href="mailto:lijecam@gmail.com" target='_blank'>Send an Email</a>
            </article>
          </a>
          <a href="https://m.me/elijah.camara.90/" target='_blank' className='outside__link'>
            <article className='contact__option'>
              <RiMessengerLine className='contact__option-icon'/>
              <h4>Messenger</h4>
              <h5>elijahcamara</h5>
              <a href="https://m.me/elijah.camara.90/" target='_blank'>Send a Message</a>
            </article>
          </a>
          <a href="https://api.whatsapp.com/send?phone=13174660608" target='_blank' className='outside__link'>
            <article className='contact__option'>
              <BsWhatsapp className='contact__option-icon'/>
              <h4>WhatsApp</h4>
              <h5>{phone_num}</h5>
              <a href="https://api.whatsapp.com/send?phone=13174660608" target='_blank'>Send a message</a>
            </article>
          </a>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="text" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message Here' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  );
};

export default Contacts