import React from 'react';
import {BsLinkedin} from 'react-icons/bs';
import {BsGithub} from 'react-icons/bs';
import {AiFillFacebook} from 'react-icons/ai';

const HeaderSocails = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/elijah-camara-a061131a2/' target='_blank'>
          <BsLinkedin />
        </a>
        <a href='https://github.com/Eli-J-Camara' target='_blank'>
          <BsGithub />
        </a>
        <a href='https://www.facebook.com/elijah.camara.90/' target='_blank'>
          <AiFillFacebook />
        </a>

    </div>
  );
}

export default HeaderSocails;