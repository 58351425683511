import React, { Fragment } from 'react';

import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Experience from './components/experience/Experience';
import Footer from './components/footer/Footer';
import Testimonials from './components/testimonials/Testimonials';
import Portfolio from './components/portfolio/Portfolio';
import Background from './components/ParticleBackgrounds/Background';


const App = () => {
  return (
      <Fragment>
        <Background />
        <Header />
        <Nav />
        <About />
        <Experience />
        <Contact />
        <Footer />
      </Fragment>
  );
}

export default App