import React from 'react'
import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';
import './about.css';

const SubSection = (props) => {
  return (
    <>
    <div className='about__cards'>
        <article className='card' onClick={props.show}>
            <FaAward className='about__icon'/>
            <h5>Certification</h5>
            <small>
            Graduated from Kenzie Academy's full stack software engineering program. Recieved 
            an A in each quarter of the one year intensive program.
            </small>
        </article>
        <article className='card'>
            <FiUsers className='about__icon'/>
            <h5>Clients</h5>
            <small>
                <ol>
                    <li>Developed an e-commerce platform for a local business to sell products on wordpress.</li>
                    <li>Created a crypto currency trading algorithm using the Binance API, python, and the Flask web framework for a private day trader.</li>
                    <li>Collaborated in creating an employee-hours management software for a small business.</li>
                </ol>
            </small>
        </article>
        <article className='card'>
            <VscFolderLibrary className='about__icon'/>
            <h5>Projects</h5>
            <small>
                <ul>
                    <a href='https://github.com/Eli-J-Camara/PetGram' target='_blank' rel="noreferrer">
                        <li className='about__projects'>Social Media Platform: Built using the Django web framework.</li>
                    </a>
                    <a href='https://github.com/Eli-J-Camara/Purchases' target='_blank' rel="noreferrer">
                        <li className='about__projects'>Purchases App: A react project I creacted.</li>
                    </a>
                    <a href='https://github.com/Eli-J-Camara/connectFour' target='_blank' rel="noreferrer">
                        <li className='about__projects'>Connect Four: This is a game I created using HTML, CSS, and JavaScript.</li>
                    </a>
                </ul>
            </small>
        </article>
    </div>
    <p>
        I am a software engineer working as a free lance developer right now. I recieved
        my full stack software engineering certification from <a href='https://kenzie.snhu.edu/' target="_blank" rel="noreferrer">Kenzie Academy</a>. 
        I am proficient in HTML, CSS, Javascript, Node, Express, and React. I have built full stack web applications 
        using the Django and Flask web frameworks. I've learned to code on multiple IDEs and I can use Windows or Mac OS X.
        For fun I like to play chess, excersize, and watch/play soccer. 
    </p>
    </>
  )
}

export default SubSection